<template>
  <div class="body">
    <div>
      <el-button @click="changeStyle()">修改</el-button>
    </div>
    可拖拽的弹出框 复数 弹出框内直播iframe 拖拉进度条 可统一调整 或者单独调整
    自定义复数弹出框 且可单独拖拽 页面音量 嵌入一个小游戏？
    <div class="card">
      <!-- <iframe
        :style="style"
        style="width: 700px; height: 400px"
        src="https://www.bilibili.com/blackboard/live/live-activity-player.html?cid=21756924&quality=0"
        frameborder="no"
        framespacing="0"
        scrolling="no"
        allow="autoplay; encrypted-media"
        allowfullscreen="true"
      ></iframe> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      radio: "1",
      style: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    changeStyle() {
      this.style;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  margin-left: 10rem;
  margin-right: 10rem;
  margin-top: 3rem;
  background-color: rgba(255, 255, 255, 0.8);
  flex: 1;
  height: 40rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .block {
    margin: 30px;
    margin-bottom: 0;
    .title {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
}
.left {
  text-align: left;
}
</style>
